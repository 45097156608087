import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Button,
  Chip,
  CircularProgress,
  InputBase,
  Box,
  Select,
  MenuItem,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useProjectContext } from '../../context/ProjectContext'; // Assuming ProjectContext is already set up
import useStyles from './styles'; // Import styles
import { Actor } from '../../context/types';
import CheckMark from './components/CheckMark';
import { Search } from '@mui/icons-material';
import { styled } from '@mui/system';
import { THEME_COLORS } from '../../context/constants';
import { deleteActor } from '../../client/api';
import { useParams } from 'react-router-dom';
import { useUserContext } from '../../context/UserContext';

interface ActorsDialogProps {
  open: boolean;
  actors?: Actor[];
  onClose: () => void;
  onSelectActors: (selectedActors: Actor[]) => void;
}

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f1f1f1',
  border: `1px solid ${THEME_COLORS.gray}`,
  padding: '2px 10px',
  borderRadius: '14px',
  marginBottom: '10px',
  maxWidth: '300px',
}));

const ActorsDialog: React.FC<ActorsDialogProps> = ({
  open,
  actors = [],
  onClose,
  onSelectActors,
}) => {
  const classes = useStyles(); // Access styles
  const { avatars, fetchAvatars } = useProjectContext(); // Get avatars from context
  const { currentWorkspace }= useUserContext();
  const { videoPageId } = useParams();
  const [selectedActors, setSelectedActors] = useState<Actor[]>(actors); // Track selected actors
  const [currentPage, setCurrentPage] = useState<number>(1); // State for pagination
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state
  const [hoveredActorId, setHoveredActorId] = useState<string | null>(null); // Track hovered actor
  const videoRefs = useRef<{ [key: string]: HTMLVideoElement | null }>({}); // Ref to store video elements
  const [activeFilter, setActiveFilter] = useState<string>('All'); // Track the active filter
  const [searchQuery, setSearchQuery] = useState<string>(''); // State for search query
  const actorsPerPage = 10; // Set the number of actors per page

  useEffect(() => {
    if (open && !avatars) {
      setIsLoading(true); // Start loading
      fetchAvatars().finally(() => setIsLoading(false)); // Stop loading when avatars are fetched
    }
  }, [open, fetchAvatars]);

  const handleSelect = async (actor: Actor) => {
    if(!currentWorkspace || !videoPageId) return
    const isSelected = selectedActors.some(
      (selected) =>  (selected.avatar_id === actor.avatar_id)
    );
    if (isSelected) {
      //remove actor from db too
      try{
        await deleteActor(actor.avatar_id, currentWorkspace?._id, videoPageId)
      } catch(e: any){
        console.error('Actor not found when deleting')
      }
      setSelectedActors((prev) =>
        prev.filter(
          (selected) => (selected.avatar_id !== actor.avatar_id)
        )
      );
    } else {
      setSelectedActors((prev) => [...prev, actor]);
    }
  };

  const handleMouseEnter = (actorId: string) => {
    setHoveredActorId(actorId);
  };

  const handleMouseLeave = () => {
    setHoveredActorId(null);
  };

  const filterActors = (actors: Actor[]) => {
    switch (activeFilter) {
      case 'Custom':
        return actors.filter(actor => (actor.gender !== 'female' && actor.gender !== 'male'));
      case 'Male':
        return actors.filter(actor => actor.gender === 'male');
      case 'Female':
        return actors.filter(actor => actor.gender === 'female');
      default:
        return actors; // "All" filter shows all actors
    }
  };

  const handleFilterChange = (filter: string) => {
    setActiveFilter(filter);
    setCurrentPage(1); // Reset to first page when the filter changes
  };

  const filteredActors = filterActors(avatars || []).filter(actor =>
    JSON.stringify(actor).toLowerCase().includes(searchQuery)
  );

  const currentActors = [
    ...selectedActors,
    ...filteredActors.filter(
      (actor) => !selectedActors.some(
        (selected) => selected.avatar_id === actor.avatar_id
      )
    ),
  ].slice((currentPage - 1) * actorsPerPage, currentPage * actorsPerPage);

  const totalPages = Math.ceil(filteredActors.length / actorsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleUnselectAll = () => {
    setSelectedActors([]);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleNext = () => {
    onSelectActors(selectedActors); // Pass selected actors to parent component
    onClose(); // Close the dialog
  };

  // Handle video playback based on hovered actor
  useEffect(() => {
    Object.keys(videoRefs.current).forEach((key) => {
      const videoEl = videoRefs.current[key];
      if (videoEl) {
        if (hoveredActorId === key) {
          videoEl.play().catch((error) => console.error("Play failed: ", error));
        } else {
          videoEl.pause();
          videoEl.currentTime = 0; // Reset video to the start
        }
      }
    });
  }, [hoveredActorId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <div className={classes.dialog}>

      <div className={classes.dialogTitle}>
        <DialogTitle className={classes.dialogTitle}>
          Select the actors you’d like to have for your ad
        </DialogTitle>
        <IconButton 
            aria-label="close" 
            onClick={onClose} 
            className={classes.closeButton}
            sx={{ color: '#FFBF0091' }} // Optional, if you want to ensure color
          >
            <CloseIcon />
        </IconButton>
      </div>



        <DialogContent sx={{padding: '12px 20px'}}>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
            {/* Search Bar */}
              <SearchBar>
                <InputBase
                  placeholder="Search actors..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  fullWidth
                />
                <IconButton>
                  <Search />
                </IconButton>
              </SearchBar>
               <div className={classes.filters}>
                {['All', 'Custom', 'Male', 'Female'].map(filter => (
                  <Chip
                    key={filter}
                    label={filter}
                    clickable
                    color={activeFilter === filter ? 'primary' : 'default'}
                    onClick={() => handleFilterChange(filter)}
                  />
                ))}
              </div>
              <Grid container spacing={2} className={classes.actorsGrid}>
                {currentActors &&
                  currentActors.map((actor: Actor) => (
                    <Grid item xs={6} sm={4} md={3} key={actor.avatar_id}>
                      <div
                        className={`${classes.actorCard} ${
                          selectedActors.some(
                            (selected) => selected.avatar_id === actor.avatar_id
                          )
                            ? classes.selected
                            : ''
                        }`}
                        onClick={() => handleSelect(actor)}
                        onMouseEnter={() => handleMouseEnter(actor.avatar_id)}
                        onMouseLeave={handleMouseLeave}
                        style={{ position: 'relative' }}
                      >
                        <div className={classes.actorInfo} style={{ position: 'absolute', zIndex: 3 }}>
                          <span style={{ marginBottom: '8px', fontWeight: 'bold' }}>
                            {actor?.avatar_name?.length > 10 ? actor?.avatar_name?.substring(0, 10) + '...' : actor?.avatar_name}
                          </span>
                          <span>{actor?.gender}</span>
                        </div>

                        {actor && (
                          <>
                            <video
                              ref={(el) => (videoRefs.current[actor.avatar_id] = el)}
                              src={actor.preview_video_url}
                              className={classes.actorVideo}
                              loop
                              preload="auto"
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: 4,
                                opacity: hoveredActorId === actor.avatar_id ? 1 : 0,
                                transition: 'opacity 0.3s ease-in-out',
                                objectFit: 'cover',
                              }}
                            />
                            <img
                              src={actor.preview_image_url}
                              alt={actor.avatar_name}
                              className={classes.actorImage}
                              style={{
                                zIndex: 0,
                                minWidth: '150px',
                                minHeight: '150px',
                                width: '100%',
                                height: '100%',
                                backgroundColor: THEME_COLORS.gray
                              }}
                            />
                            <CheckMark isVisible={selectedActors.some((selected) => selected.avatar_id === actor.avatar_id)} />
                          </>
                        )}
                      </div>
                    </Grid>
                  ))}
              </Grid>

              <div className={classes.paginationControls}>
                <Button
                  variant="contained"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </Button>
                <Select
                  value={currentPage}
                  onChange={(e) => handlePageChange(Number(e.target.value))}
                  style={{ margin: '0 10px' }}
                >
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                    <MenuItem key={page} value={page}>
                      Page {page}
                    </MenuItem>
                  ))}
                </Select>
                <Button
                  variant="contained"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </DialogContent>

        <div className={classes.dialogFooter}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px'}}>
            <span>{selectedActors.length} actors selected</span>
            <Button variant="outlined" color="primary" onClick={handleUnselectAll}>
              Unselect All
            </Button>
          </div>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Next
            </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ActorsDialog;
