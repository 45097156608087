import React, { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemButton,
  Divider,
  Typography,
  IconButton,
  Tooltip,
  Button,
  InputBase,
  Pagination,
  Popover, // Import Button from Material UI
} from '@mui/material';
import { AccountCircle, AddCircle, Check, ExpandLess, ExpandMore, Search as SearchIcon, Settings, UnfoldLess, UnfoldMore } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for redirecting
import { useProjectContext } from '../../context/ProjectContext';
import { Project, VideoPage, Workspace } from '../../context/types';
import { styled } from '@mui/system';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateProjectDialog from '../CreateProjectDialog';
import CreateVideoPageDialog from '../CreateVideoPageDialog';
import { createWorkspace, deleteProject, getAllProjects, getProjectById, updateProject } from '../../client/api';
import { useUserContext } from '../../context/UserContext'; // Import useUserContext for logout
import EditProjectDialog from '../EditProjectDialog';
import { THEME_COLORS } from '../../context/constants';
import ConfigurationDialog from '../ConfigurationDialog';
import { fetchUser } from '../../utils/utils';
import CreateWorkspaceDialog from '../CreateWorkspaceDialog';
import { useNotification } from '../../context/NotificationContext';
import AccountDialog from '../AccountDialog';

// Styled Components
const SidebarContainer = styled(Box)(({ theme }) => ({
  width: 350,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between', // Ensure the logout button stays at the bottom
  backgroundColor: theme.palette.background.default,
  fontFamily: 'Lexend, sans-serif',
  color: '#636363',
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const Logo = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4,
  marginRight: theme.spacing(1),
  color: '#000',
  fontWeight: 'bold',
  fontSize: 18,
}));

const ProjectList = styled(List)(({ theme }) => ({
  padding: theme.spacing(1),
}));

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f1f1f1',
  border: `1px solid ${THEME_COLORS.gray}`,
  padding: '2px 10px',
  margin: theme.spacing(2),
  borderRadius: '14px',
}));

const Sidebar: React.FC = () => {
  const [open, setOpen] = useState<{ [key: string]: boolean }>({});
  const { currentProject, projects, setCurrentProject, setProjects } = useProjectContext();
  const { logout } = useUserContext(); // Get the logout function from context
  const { user, currentWorkspace, setCurrentWorkspace, setUser} = useUserContext();
  const navigate = useNavigate(); // For redirecting after logout
  const [openNewProjectDialog, setOpenNewProjectDialog] = useState(false);
  const [openNewVideoPageDialog, setOpenNewVideoPageDialog] = useState(false);
  const [selectedProjectForVideoPage, setSelectedProjectForVideoPage] = useState<Project | null>(null);
  const [loadingCreateVideoPage, setLoadingCreateVideoPage] = useState(false); // Loading state
  const [hoveredProjectId, setHoveredProjectId] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit] = useState(10);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const openDropdown = Boolean(anchorEl);
  const [workspaceConfigDialogOpen, setWorkspaceConfigDialogOpen] = useState(false);
  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCreateWorkspaceDialog, setOpenCreateWorkspaceDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { addNotification } = useNotification();

  const handleAddNewWorkspace = async () => {
    setOpenCreateWorkspaceDialog(true);
  };

  const handleCreateWorkspace = async (workspaceName: string) => {
    if (!workspaceName || !user) return; // Prevent creating an empty workspace
    try {
      const newWorkspace = await createWorkspace(workspaceName, user?._id);
      // Update local user state with new workspace
      const userData = await fetchUser(user?._id)
      if(userData){
       setUser(userData)
      }
      addNotification('Workspace created successfully!', 'success');
      setCurrentWorkspace(newWorkspace)
    } catch (error) {
      console.error('Error creating workspace:', error);
      addNotification('Failed to create workspace.', 'error');
    } finally {
      setOpenCreateWorkspaceDialog(false);
    }
  };

  const handleWorkspaceConfigDialogOpen = () => {
    setWorkspaceConfigDialogOpen(true);
  };

  const handleWorkspaceConfigDialogClose = () => {
    setWorkspaceConfigDialogOpen(false);
  };

  // Fetch projects with pagination and search
  const fetchProjects = async (search: string = '', currentPage: number = 1) => {
    if(!currentWorkspace) return
    try {
      const { projects, total } = await getAllProjects(currentPage, limit, search, currentWorkspace?._id);
      setProjects(projects);
      setTotalPages(Math.ceil(total / limit));
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const handleToggle = (projectId: string) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [projectId]: !prevOpen[projectId],
    }));
  };

  const handleCreateNewProject = () => {
    setOpenNewProjectDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenNewProjectDialog(false);
  };

  const handleProjectCreated = async (newProject: Project) => {
    if(!currentWorkspace) return;
    setCurrentProject(newProject);
    const projectsResponse = await getAllProjects(1, limit, '', currentWorkspace?._id);
    setProjects(projectsResponse.projects);
    setOpenNewProjectDialog(false);
    navigate(`projects/${newProject._id}`)
  };

  const handleCreateNewVideoPage = (project: Project) => {
    setSelectedProjectForVideoPage(project);
    setOpenNewVideoPageDialog(true);
  };

  const handleCloseVideoPageDialog = () => {
    setOpenNewVideoPageDialog(false);
    setSelectedProjectForVideoPage(null);
  };

  // Function to fetch project by ID and update currentProject in context
  const handleSetCurrentProject = async (projectId: string) => {
    try {
      const updatedProject = await getProjectById(projectId);
      setCurrentProject(updatedProject);
    } catch (error) {
      console.error('Error fetching project by ID:', error);
    }
  };

  const handleNavigateToProject = async (projectId: string) => {
    navigate(`/projects/${projectId}`)
  }

  const handleOpenEditDialog = (project: Project) => {
    setSelectedProject(project);
    setOpenEditDialog(true);
  };

  // Handle saving the edited project
  const handleSaveProjectName = async (newName: string) => {
    if (selectedProject) {
      await updateProject(selectedProject._id, { name: newName });
      // Optionally, update the projects in context
      setProjects((prevProjects) =>
        prevProjects.map((proj) =>
          proj._id === selectedProject._id ? { ...proj, name: newName } : proj
        )
      );
    }
  };

  // Handle deleting a project
  const handleDeleteProject = async (id: string) => {
    setLoadingDelete(true);
    try {
      await deleteProject(id);
      setProjects((prevProjects) => prevProjects.filter((proj) => proj._id !== id));
      addNotification('Project deleted', 'success')
      navigate('/')
    } catch (error: any) {
      console.error('Error deleting project:', error);
      addNotification('Error deleting project: '+error.response.data.message, 'error');
    } finally {
      setLoadingDelete(false);
    }
  };

  const onVideoPageCreated = async (projectId: string, newVideoPage: VideoPage) => {
    try {
      if(!currentWorkspace) return;
      setLoadingCreateVideoPage(true);
      const updatedProject = await getProjectById(projectId);
      setCurrentProject(updatedProject);
      const projectsResponse = await getAllProjects(1, 10, '', currentWorkspace?._id);
      setProjects(projectsResponse.projects);
      setOpenNewVideoPageDialog(false);
      navigate(`/projects/${projectId}/video/${newVideoPage._id}`)
    } catch (error) {
      console.error('Error fetching updated project data:', error);
    } finally {
      setLoadingCreateVideoPage(false);
    }
  };

  const handleAccountDialogClose = () => {
    setAccountDialogOpen(false); // Close AccountDialog
  };
  const handleAccountDialogOpen = () => {
    setAccountDialogOpen(true); // Open AccountDialog
  };

  const handleLogout = () => {
    logout(); // Remove user from context and localStorage
    navigate('/login'); // Redirect to login page
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

    // Handle opening and closing the dropdown menu
  const handleDropdownToggle = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleWorkspaceSelect = (workspace: Workspace) => {
    // todo update here too
    setCurrentWorkspace(workspace); // Set selected workspace as current
    setAnchorEl(null); // Close dropdown
    navigate('/')
  };

  const getWorkspaceArray = () => {
    let tempWorkspaces: Workspace[] = []
    if(user?.ownedWorkspaces){
      tempWorkspaces = [...tempWorkspaces, ...user.ownedWorkspaces]
    }
    if(user?.adminWorkspaces){
      tempWorkspaces = [...tempWorkspaces, ...user.adminWorkspaces]
    }
    if(user?.memberWorkspaces){
      tempWorkspaces = [...tempWorkspaces, ...user.memberWorkspaces]
    }
    return tempWorkspaces
  }

  useEffect(() => {
    fetchProjects(searchQuery, page);
  }, [searchQuery, page]);

  useEffect(() => {
    setWorkspaces(getWorkspaceArray())
  }, [user])

  return (
    <SidebarContainer>
      {/* Logo and Name Section */}
       <Box >
        <Box onClick={handleDropdownToggle} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: '10px' }}>
          <LogoContainer style={{ cursor: 'pointer' }}>
            <Logo>L</Logo>
            <Typography variant="h6" sx={{ color: '#000', fontWeight: 'bold' }}>
              LetsLaunch
            </Typography>
          </LogoContainer>
          {/** Conditional rendering of the icon based on openDropdown state */}
          {openDropdown ? (
            <UnfoldLess sx={{ cursor: 'pointer' }} />
          ) : (
            <UnfoldMore sx={{ cursor: 'pointer' }} />
          )}
        </Box>
          

       {/* Dropdown Menu for Workspaces */}
        <Popover
          open={openDropdown}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        >
          <Box sx={{ padding: '8px', minWidth: '200px' }}>
            <List>
              <Box display="flex" alignItems="center" >
                <Typography sx={{color: THEME_COLORS.darkGray, fontSize: '14px'}}>Workspaces</Typography>
                <Tooltip title="Create new workspace">
                  <IconButton onClick={handleAddNewWorkspace}>
                    <AddCircle sx={{width: '16px'}} />
                  </IconButton>
                </Tooltip>
              </Box>              <Box sx={{marginBottom: '20px'}}>
                {workspaces.map((workspace) => (
                  <ListItemButton
                    key={workspace._id}
                    onClick={() => handleWorkspaceSelect(workspace)}
                    selected={currentWorkspace?._id === workspace._id} // Highlight current workspace
                  >
                    <ListItemText primary={workspace.name} />
                    {currentWorkspace?._id === workspace._id ? <Check /> : ''}
                    
                  </ListItemButton>
                ))}
              </Box>

              <Divider />
              <ListItemButton onClick={handleLogout}>
               <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleLogout} // Call the logout function
                >
                  Logout
                </Button>
              </ListItemButton>
              {user && (
                (user.ownedWorkspaces?.some(workspace => workspace._id === currentWorkspace?._id) ||
                user.adminWorkspaces?.some(workspace => workspace._id === currentWorkspace?._id)) && (
                  <Box sx={{marginTop: '12px'}}>
                  <ListItemButton onClick={handleWorkspaceConfigDialogOpen} sx={{ opacity: 0.8}}>
                    <Settings sx={{width: '18px'}} />
                    <Typography sx={{marginLeft: '5px', fontSize: '14px'}}>Current workspace settings</Typography>
                  </ListItemButton>
                  <ListItemButton onClick={handleAccountDialogOpen} sx={{opacity: 0.8}}>
                    <AccountCircle sx={{width: '18px'}} />
                    <Typography sx={{marginLeft: '5px', fontSize: '14px'}}>Account settings</Typography>
                  </ListItemButton>
                  </Box>
                )
              )}
              {user?.organization?.builderAccess && (
              <ListItemButton
                onClick={() => window.open(process.env.REACT_APP_BUILDER_URL, '_blank')}
                sx={{ opacity: 0.8, marginTop: '12px' }}
              >
                <Box
                  component="img"
                  src={`${process.env.PUBLIC_URL}/builder-logo-512.png`}
                  alt="Builder Logo"
                  sx={{ width: '18px', height: '18px', marginRight: '5px' }}
                />
                <Typography sx={{ fontSize: '14px' }}>Landing Page Builder</Typography>
              </ListItemButton>
            )}
              {workspaceConfigDialogOpen && currentWorkspace &&
              <ConfigurationDialog
                open={workspaceConfigDialogOpen}
                onClose={handleWorkspaceConfigDialogClose}
                currentWorkspace={currentWorkspace}
              />
              }
              {
                accountDialogOpen && user &&
                <AccountDialog open={accountDialogOpen} onClose={handleAccountDialogClose} user={user} />
              }
            </List>
          </Box>
        </Popover>


        {/* Projects Section */}
        <ProjectList>
          <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{fontSize: '12px', opacity: 0.5}}> Logged in as <b>{user?.email}</b></span>
          </Box>
          <ListItem
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <ListItemText primary="Projects" />
            <Tooltip title="Create New Project">
              <IconButton
                onClick={handleCreateNewProject}
                sx={{ color: '#F2C94C' }}
              >
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </ListItem>
          <Divider />
          {/* Search Field */}
          <SearchBar>
            <InputBase
              placeholder="Search Projects..."
              value={searchQuery}
              onChange={handleSearchChange}
              fullWidth
            />
            <IconButton>
              <SearchIcon />
            </IconButton>
          </SearchBar>
          {projects.map((project: Project) => (
            <Box key={project._id}>
              <ListItemButton
                onMouseEnter={() => setHoveredProjectId(project._id)}
                onMouseLeave={() => setHoveredProjectId(null)}
                onClick={() => {
                  handleToggle(project._id);
                  handleSetCurrentProject(project._id);
                }}
              >
                {open[project._id] ? <ExpandLess /> : <ExpandMore />}
                <ListItemText primary={project.name} onClick={() => handleNavigateToProject(project._id)}/>
                
                {/* Show icons on hover */}
                {hoveredProjectId === project._id && (
                  <>
                    <Tooltip title="Edit Project">
                      <IconButton onClick={() => handleOpenEditDialog(project)} sx={{ color: '#F2C94C' }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Project">
                      <IconButton onClick={() => handleDeleteProject(project._id)} sx={{ color: '#F2C94C' }}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                
                <Tooltip title="New Video Page">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCreateNewVideoPage(project);
                    }}
                    sx={{ color: '#F2C94C' }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Tooltip>
              </ListItemButton>
              <Collapse in={open[project._id]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {project.videoPages.map((videoPage) => (
                    <ListItemButton
                      key={videoPage._id}
                      sx={{ pl: 4 }}
                      component={Link}
                      to={`/projects/${project._id}/video/${videoPage._id}`}
                      onClick={() => handleSetCurrentProject(project._id)}
                    >
                      <ListItemText primary={videoPage.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </Box>
          ))}
        </ProjectList>
        {/* Pagination */}
        <Box sx={{ display: totalPages > 1 ? 'flex' : 'none', justifyContent: 'center', padding: '10px 0' }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
          />
        </Box>

        {/* Create Project Dialog */}
        <CreateProjectDialog
          open={openNewProjectDialog}
          onClose={handleCloseDialog}
          onProjectCreated={handleProjectCreated}
        />

        {/* Create Video Page Dialog */}
        {selectedProjectForVideoPage && (
          <CreateVideoPageDialog
            open={openNewVideoPageDialog}
            onClose={handleCloseVideoPageDialog}
            projectId={selectedProjectForVideoPage._id}
            onVideoPageCreated={(newVideoPage: VideoPage) => onVideoPageCreated(selectedProjectForVideoPage._id, newVideoPage)}
            loading={loadingCreateVideoPage}
          />
        )}
      </Box>


      <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
        <span style={{fontSize: '12px', opacity: 0.5}}> LETSLAUNCH BETA VERSION 0.8.5</span>
      </Box>
      {/* WorkspaceDialog Component */}
      {openCreateWorkspaceDialog && 
      <CreateWorkspaceDialog 
        open={openCreateWorkspaceDialog} 
        onClose={() => setOpenCreateWorkspaceDialog(false)} 
        user={user} 
        onWorkspaceCreated={() => console.log('')}
        handleCreateWorkspace={handleCreateWorkspace}
      />}
      {openEditDialog && 
      <EditProjectDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        project={selectedProject}
        onSave={handleSaveProjectName}
      />}
    </SidebarContainer>
  );
};

export default Sidebar;
