import React, { useState, useRef, useEffect } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import { Button, Checkbox, Slider, Switch, MenuItem, Select, SelectChangeEvent, RadioGroup, FormControlLabel, Radio, Box, Typography } from '@mui/material';
import useStyles from './style';
import { Actor, ElevenLabsVoice, HeygenVoice, VideoPage } from '../../context/types';
import { addHeygenVoice, addVoiceToActor, elevenLabsFF, getElevenLabsVoice, getElevenLabsVoices, getVideoPageById, listHeygenVoices, removeActorCustomVoice, updateVoiceConfiguration } from '../../client/api';
import { useParams } from 'react-router-dom';
import { THEME_COLORS } from '../../context/constants';
import { useUserContext } from '../../context/UserContext';
import VoiceSelectionModal from './components/HeygenVoicesDialog';

interface VoicePlayerProps {
  actor: Actor;
  actorId: string;
  audioSrc: string;
  isSelected: boolean;
  onSelect: (actorId: string) => void;
  setVideoPage: (videoPage: VideoPage) => void;
  ensureActorsRegistered: any;
}

const VoicePlayer: React.FC<VoicePlayerProps> = ({
  actor,
  actorId,
  audioSrc,
  isSelected,
  onSelect,
  setVideoPage,
  ensureActorsRegistered
}) => {
  const classes = useStyles();
  const { currentWorkspace } = useUserContext();
  const audioRef = useRef<HTMLAudioElement>(null); //actor audio
  const currentAudioRef = useRef<HTMLAudioElement | null>(null); // voice list audio
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [voiceSettings, setVoiceSettings] = useState( actor?.voiceConfiguration ?? {
    stability: 0.1,
    similarity_boost: 0.3,
    style: 0.2,
    use_speaker_boost: false,
  });
  const [voiceSelection, setVoiceSelection] = useState<'heygen' | 'elevenLabs'>(actor?.elevenLabsVoiceId ?'elevenLabs' : 'heygen');
  const [customVoices, setCustomVoices] = useState<ElevenLabsVoice[]>([]);
  const [heygenVoices, setHeygenVoices] = useState<HeygenVoice[]>([]);
  const [selectedVoiceId, setSelectedVoiceId] = useState<string | null>(actor?.elevenLabsVoiceId ?? null);
  const [previewUrl, setPreviewUrl] = useState('')
  const [selectedHeygenVoiceId, setSelectedHeygenVoiceId] = useState<string | null>(actor?.heygenVoiceId ?? null);
  const [heygenVoiceModalOpen, setHeygenVoiceModalOpen] = useState(false)
  const [elevenLabsEnabled, setElevenLabsEnabled] = useState(false);
  const { videoPageId } = useParams();

  const togglePlay = async () => {
    if (!audioRef.current) return;

    const audioUrl = audioRef.current.src;
    const isWavFile = audioUrl.endsWith('.wav');

    if (isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
      return;
    }

    if (isWavFile) {
      try {
        // Fetch the WAV file as an ArrayBuffer
        const response = await fetch(audioUrl);
        const arrayBuffer = await response.arrayBuffer();

        // Decode the audio using Web Audio API
        const audioContext = new window.AudioContext();
        const decodedData = await audioContext.decodeAudioData(arrayBuffer);

        // Create a buffer source and play it
        const source = audioContext.createBufferSource();
        source.buffer = decodedData;
        source.connect(audioContext.destination);
        source.start(0);

        source.onended = () => {
          setIsPlaying(false); // Reset play state when the audio finishes
        };

        setIsPlaying(true);
      } catch (error) {
        console.error('Error processing WAV file:', error);
      }
    } else {
      // For non-WAV files, play as usual
      try {
        await audioRef.current.play();
        setIsPlaying(true);
      } catch (error) {
        console.error('Error playing audio:', error);
      }
    }
  };


  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const handleVoiceSettings = async () => {
    if (!videoPageId) return;
    if (!actor.elevenLabsVoiceId) {
      setLoading(true);
      try {
        //const updatedVideoPage = await getVideoPageById(videoPageId);
        //setVideoPage(updatedVideoPage);
      } catch (error) {
        console.error('Error adding voice to actor:', error);
      } finally {
        setLoading(false);
      }
    }
    setShowSettings(!showSettings);
  };

  const updateVideoPage = async () => {
    if(!videoPageId) return
    const updatedVideoPage = await getVideoPageById(videoPageId);
    setVideoPage(updatedVideoPage);
  }

  const handleVoiceSelectionChange = async (event: SelectChangeEvent<string | null>) => {
    const selectedId = event.target.value as string;
    setSelectedVoiceId(selectedId);
    await handleAddCustomVoice(selectedId);
  };

  const handleAddCustomVoice = async (selectedId: string) => {
    if(!currentWorkspace || !selectedId || !videoPageId) return;
    const selectedVoice = customVoices.find((voice) => voice.voice_id === selectedId);
    if (selectedVoice) {
      audioRef.current!.src = selectedVoice.preview_url; // Set preview URL to audio element
      const registeredIds = await ensureActorsRegistered([actor])
      if(registeredIds.length){
        await addVoiceToActor(registeredIds[0], selectedVoice.voice_id, currentWorkspace?._id, videoPageId)
      }
      if(selectedVoice.settings){
        setVoiceSettings(selectedVoice.settings)
      }
      await updateVideoPage()
    }
  }

  const handleHeygenVoiceSelectionChange = async (event: SelectChangeEvent<string | null>) => {
    const selectedId = event?.target.value as string;
    await handleAddHeygenVoice(selectedId);
  };

  //to do
  const handleAddHeygenVoice = async (selectedId: string) => {
    if(!currentWorkspace || !selectedId || !videoPageId) return;
    setSelectedHeygenVoiceId(selectedId);
    const selectedVoice = heygenVoices.find((voice) => voice.voice_id === selectedId);
    console.log(selectedVoice)
    if (selectedVoice) {
      audioRef.current!.src = selectedVoice.preview_audio; // Set preview URL to audio element
      const registeredIds = await ensureActorsRegistered([actor])
      if(registeredIds.length){
        await addHeygenVoice(currentWorkspace._id,registeredIds[0], selectedId, selectedVoice.preview_audio, videoPageId)
      }
      await updateVideoPage()
    }
  }

  const handleSettingChange = async (key: string, value: any) => {
    const updatedSettings = { ...voiceSettings, [key]: value };
    setVoiceSettings(updatedSettings);

    // Update voice configuration in the database
    try {
      await updateVoiceConfiguration(actorId, updatedSettings);
      if(actor.elevenLabsVoiceId){
        const voice = await getElevenLabsVoice(actor.elevenLabsVoiceId)
        audioRef.current!.src = voice.preview_url; // Set preview URL to audio element
        audioRef.current!.play();
      }
    } catch (error) {
      console.error('Error updating voice configuration:', error);
    }
  };

   useEffect(() => {
    const fetchVoices = async () => {
      try {
        const response = await elevenLabsFF()
        const isEnabled = response === 'true'
        setElevenLabsEnabled(isEnabled)
        if(isEnabled){
          const voices = await getElevenLabsVoices();
          setCustomVoices(voices);
        }
      } catch (error) {
        console.error('Error fetching Eleven Labs voices:', error);
      }
    };
    const fetchHeygenVoices = async () => {
      try{
        const heygenVoices = await listHeygenVoices()
        console.log(heygenVoices)
        setHeygenVoices(heygenVoices)
      } catch (error) {
        console.error('Error fetching Heygen voices:', error);
      }
      
    }
    fetchVoices();
    fetchHeygenVoices();
  }, []);

  const playAudio = (url: string) => {
    // Stop any currently playing audio
    if (currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0; // Reset to the beginning
    }

    // Create and play new audio
    const audio = new Audio(url);
    currentAudioRef.current = audio;

    audio.play().catch((error) => {
      console.error('Error playing audio:', error);
    });
  };

  const stopAudio = () => {
    if (currentAudioRef.current) {
      currentAudioRef.current.pause();
      currentAudioRef.current.currentTime = 0; // Reset to the beginning
      currentAudioRef.current = null; // Clear reference
    }
  };

  useEffect(() => {
    const removeActorVoice = async () => {
      if(!currentWorkspace || !videoPageId) return
        try{
           if(actor._id && actor.elevenLabsVoiceId){
              await removeActorCustomVoice(actor._id, currentWorkspace?._id, videoPageId)
           }
        } catch(e: any){
            console.error('Error deleting custom voice')
        }
    }
    if(voiceSelection === 'heygen'){
        removeActorVoice();
        audioRef.current!.src = actor.preview_video_url; // Set preview URL to audio element
    } else if (selectedVoiceId) {
        handleAddCustomVoice(selectedVoiceId)
    }
  }, [voiceSelection])

    useEffect(() => {
      audioRef.current!.src = audioSrc
  }, [audioSrc])

  return (
    <div className={classes.playerContainer}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {/* Checkbox */}
        <Checkbox
          checked={isSelected}
          onChange={() => onSelect(actor.avatar_id)}
          className={classes.checkbox}
        />

        {/* Avatar Thumbnail */}
        <img
          src={actor.preview_image_url}
          alt={actor.avatar_name}
          className={classes.avatarThumbnail}
        />
        {/* Play Button */}
        <button onClick={togglePlay} className={classes.playButton}>
          {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </button>

        {/* Sound Wave */}
        <div className={classes.soundWave} />

        {/* Audio Timer */}
        <div className={classes.audioTimer}>
          {isPlaying ? formatTime(currentTime) : formatTime(duration)}
        </div>
        {/* Voice Settings Button */}
        <Button
          onClick={handleVoiceSettings}
          style={{ color: THEME_COLORS.yellow, background: 'none', border: 'none', fontSize: '11px', fontWeight: 'bold', textTransform: 'none' }}
        >
          Voice Settings
        </Button>
      </div>

      <div>
        {/* Loading State */}
        {loading && <div>Loading...</div>}

        {/* Voice Settings Panel */}
        {showSettings && (
          <div className={classes.voiceSettingsPanel}>
            {/* Voice Type Selection */}
                          <span style={{fontSize: '11px', color: THEME_COLORS.darkGray}}>Choose voice from...</span>

            <div style={{display: 'flex'}}>
              <RadioGroup
                value={voiceSelection}
                onChange={(e) => setVoiceSelection(e.target.value as 'heygen' | 'elevenLabs')}
                row
                >
                    <FormControlLabel
                    value="heygen"
                    control={<Radio className={classes.radio} />}
                    label={<span className={classes.label}>Voice Library</span>}
                    />
                    <FormControlLabel
                    value="elevenLabs"
                     className={classes.radio}
                    control={<Radio className={classes.radio}/>}
                    label={<span className={classes.label}>Eleven Labs {elevenLabsEnabled ? '' : <span style={{fontSize: '11px', color: THEME_COLORS.darkGray}}>(unavailable)</span>} </span>}
                    disabled
                    />
                </RadioGroup>
            </div>

            {/* Voice Settings */}
            {voiceSelection === 'elevenLabs' && (
              <>
                <div className={classes.settingsRow}>
                  <span>Voice: </span>
                  
                  <Select
                    value={selectedVoiceId}
                    onChange={handleVoiceSelectionChange}
                    displayEmpty
                    renderValue={(value) =>
                      value ? customVoices.find((voice) => voice.voice_id === value)?.name : 'Select Voice'
                    }
                     style={{fontSize: '12px', width: '100%', maxHeight:'30px', marginLeft: '8px'}}
                  >
                    {customVoices.map((voice) => (
                        <MenuItem
                        key={voice.voice_id}
                        value={voice.voice_id}
                        style={{ fontSize: '12px' }}
                        onMouseEnter={() => playAudio(voice.preview_url)}
                        onMouseLeave={stopAudio}
                        >
                            {voice.name}
                        </MenuItem>
                    ))}
                  </Select>
                </div>

                {/* Settings for the Selected Voice */}
                {selectedVoiceId && (
                  <div className={classes.settings}>
                    <div className={classes.settingsRow}>
                      <span>Stability</span>
                      <Slider
                        value={voiceSettings?.stability}
                        onChange={(e, value) => handleSettingChange('stability', value)}
                        min={0}
                        max={1}
                        step={0.1}
                      />
                    </div>
                    <div className={classes.settingsRow}>
                      <span>Similarity</span>
                      <Slider
                        value={voiceSettings?.similarity_boost}
                        onChange={(e, value) =>
                          handleSettingChange('similarity_boost', value)
                        }
                        min={0}
                        max={1}
                        step={0.1}
                      />
                    </div>
                    <div className={classes.settingsRow}>
                      <span>Style</span>
                      <Slider
                        value={voiceSettings?.style}
                        onChange={(e, value) => handleSettingChange('style', value)}
                        min={0}
                        max={1}
                        step={0.1}
                      />
                    </div>
                    <div className={classes.settingsRow}>
                      <span>Speaker Boost</span>
                      <Switch
                        checked={voiceSettings?.use_speaker_boost}
                        onChange={(e) =>
                          handleSettingChange('use_speaker_boost', e.target.checked)
                        }
                        
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            {voiceSelection === 'heygen' && (
              <>
                <div className={classes.settingsRow}>
                  <span>Voice: </span>
                  <button className={classes.voiceButton} onClick={(e) => { e.preventDefault(); setHeygenVoiceModalOpen(true)} }>
                    {selectedHeygenVoiceId ? heygenVoices.find((voice) => voice.voice_id === selectedHeygenVoiceId)?.name : 'Select Voice'}
                  </button>
                    
                  <VoiceSelectionModal
                    open={heygenVoiceModalOpen}
                    onClose={() => setHeygenVoiceModalOpen(false)}
                    heygenVoices={heygenVoices}
                    onSelectVoice={async (voiceId) => await handleAddHeygenVoice(voiceId)}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* Audio Element */}
      <audio
        ref={audioRef}
        src={audioSrc}
        onTimeUpdate={handleTimeUpdate}
        onLoadedMetadata={handleLoadedMetadata}
        onEnded={() => setIsPlaying(false)}
        style={{ display: 'none' }} // Hide default audio controls
      />
    </div>
  );
};

export default VoicePlayer;
