import axios from 'axios';
import { ElevenLabsVoice } from '../context/types';
import { delay } from '../utils/utils';

const url = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

const api = axios.create({
  baseURL: url,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'X-API-Key': apiKey,
  },
});

export default api;

// Actors API
export const getActors = async () => {
  const response = await api.get('/api/actors');
  return response.data;
};

export const getInternalActors = async () => {
  const response = await api.get('/api/actors/internal');
  return response.data;
};

export const registerActor = async (actorData: object) => {
  const response = await api.post('/api/actors', actorData);
  return response.data;
};

export const deleteActor = async (id: string, workspaceId: string, videoPageId: string) => {
  const response = await api.delete(`/api/actors/${workspaceId}/${videoPageId}/${id}`);
  return response.data;
};

// Fetch actor by avatar ID
export const getActorByAvatarId = async (avatarId: string, workspaceId: string, videoPageId: string) => {
  const response = await api.get(`/api/actors/avatar/${workspaceId}/${videoPageId}/${avatarId}`);
  return response.data;
};



// Generated Video API
export const addGeneratedVideo = async (videoData: object) => {
  const response = await api.post('/api/generated-videos', videoData);
  return response.data;
};

export const updateGeneratedVideo = async (id: string, videoData: object) => {
  const response = await api.put(`/api/generated-videos/${id}`, videoData);
  return response.data;
};

export const deleteGeneratedVideo = async (id: string) => {
  const response = await api.delete(`/api/generated-videos/${id}`);
  return response.data;
};

// Project API
export const createProject = async (name: string, workspaceId: string) => {
  const response = await api.post('/api/projects', { name, workspaceId });
  return response.data;
};

export const updateProject = async (id: string, projectData: object) => {
  const response = await api.put(`/api/projects/${id}`, projectData);
  return response.data;
};

export const deleteProject = async (id: string) => {
  const response = await api.delete(`/api/projects/${id}`);
  return response.data;
};

// NEW: Get project by ID
export const getProjectById = async (id: string) => {
  const response = await api.get(`/api/projects/${id}`);
  return response.data;
};

// NEW: Get all projects with pagination (default 10 results)
export const getAllProjects = async (
  page: number = 1,
  limit: number = 10,
  search: string = '',
  workspaceId: string,
) => {
  const response = await api.get(`/api/projects/workspace/${workspaceId}?page=${page}&limit=${limit}&search=${encodeURIComponent(search)}`);
  return response.data;
};

export const addVideoPageToProject = async (id: string, name: string, userId = '') => {
  const response = await api.post(`/api/projects/${id}/videopages`, {name, userId});
  return response.data;
};

export const removeVideoPageFromProject = async (id: string, videoPageId: string) => {
  const response = await api.delete(`/api/projects/${id}/videopages/${videoPageId}`);
  return response.data;
};

// VideoPage API
export const createVideoPage = async (videoPageData: object, userId: string) => {
  const response = await api.post('/api/videopages', { videoPageData, userId}) ;
  return response.data;
};

export const updateVideoPage = async (id: string, videoPageData: object, userEmail: string) => {
  const response = await api.put(`/api/videopages/${id}`, {videoPageData, userEmail} );
  return response.data;
};

export const deleteVideoPage = async (id: string) => {
  const response = await api.delete(`/api/videopages/${id}`);
  return response.data;
};

export const getVideoPageById = async (id: string) => {
  const response = await api.get(`/api/videopages/${id}`);
  return response.data;
};

export const generateVideo = async (workspaceId: string, id: string, actorId: string, voiceId: string, script: string, scriptId: string, dimension: { width: number, height: number }, test=false, userEmail: string, videoPageId: string) => {
  const response = await api.post(`/api/videopages/${id}/generate-video`, {
    workspaceId,
    actorId,
    voiceId,
    script,
    scriptId,
    dimension,
    test,
    userEmail,
    videoPageId
  });
  
  return response.data;
};

//generate multiple videos using 1 script and multiple actors
export const generateVideosMultipleActors = async (
  workspaceId: string,
  id: string,
  actorIds: string[],
  voiceIds: Record<string,string>,
  script: string,
  scriptId: string,
  dimension: { width: number, height: number },
  test: boolean,
  userEmail: string,
  videoPageId: string
) => {
  // Map each actorId to an API call and wait for all to resolve
  const responses = await Promise.all(
    actorIds.map((actorId) =>
      api.post(`/api/videopages/${id}/generate-video`, {
        workspaceId,
        actorId,
        voiceId: voiceIds[actorId],
        script,
        scriptId,
        dimension,
        test,
        userEmail,
        videoPageId
      })
    )
  );

  // Extract data from each response
  return responses.map((response) => response.data);
};

export const generateVideosMultipleScripts = async (
  workspaceId: string,
  videoPageId: string,
  actorId: string,
  voiceId: string,
  scripts: { text: string; scriptId: string }[], // Array of scripts with text and scriptId
  dimension: { width: number; height: number },
  test: boolean,
  userEmail: string,
) => {
  try {
    const response = await api.post(`/api/videopages/${videoPageId}/generate-video/scripts`, {
      workspaceId,
      actorId,
      voiceId,
      scripts,
      dimension,
      test,
      userEmail,
      videoPageId
    });
    return response.data.videoIds;  // Return generated video IDs
  } catch (error) {
    console.error("Error generating videos:", error);
    throw error;  // Rethrow error to handle it in the calling function
  }
};

export const removeGeneratedVideo = async (videoPageId: string, videoId: string) => {
  const response = await api.delete(`/api/videopages/${videoPageId}/generated-videos/${videoId}`);
  return response.data;
};

export const updateGeneratedVideoStatus = async (videoId: string) => {
  const response = await api.put(`/api/generated-videos/${videoId}/status`);
  return response.data;
};

export const updateScript = async (id: string, scriptData: object, userEmail: string) => {
  const response = await api.put(`/api/videopages/${id}/script`, {...scriptData, userEmail});
  return response.data;
};

// VideoPage API - Actors
export const updateVideoPageActors = async (id: string, actorIds: string[] | undefined, userEmail: string) => {
  const response = await api.put(`/api/videopages/${id}/actors`, { actorIds, userEmail });
  return response.data;
};

export const removeVideoPageActor = async (id: string, actorId: string) => {
  const response = await api.delete(`/api/videopages/${id}/actors`, { data: { actorId } });
  return response.data;
};

// Voice API
export const addVoice = async (voiceData: object) => {
  const response = await api.post('/api/voices', voiceData);
  return response.data;
};

export const updateVoice = async (id: string, voiceData: object) => {
  const response = await api.put(`/api/voices/${id}`, voiceData);
  return response.data;
};

export const deleteVoice = async (id: string) => {
  const response = await api.delete(`/api/voices/${id}`);
  return response.data;
};

// Authentication API
export const registerUser = async (name: string, email: string, password: string, role: string, organizationKey: string/*, accessKey: string*/) => {
  const response = await api.post('/api/auth/signup', { name, email, password, role, organizationKey/*, accessKey*/ });
  return response.data;
};

export const loginUser = async (email: string, password: string) => {
  const response = await api.post('/api/auth/login', { email, password });
  return response.data;
};

export const getUserById = async (id: string) => {
  const response = await api.get(`/api/auth/user/${id}`);
  return response.data;
};

// Workspace API
export const createWorkspace = async (name: string, userId: string) => {
  const response = await api.post('/api/workspaces', { name, owner: userId });
  return response.data;
};

export const updateWorkspace = async (id: string, workspaceData: object) => {
  const response = await api.put(`/api/workspaces/${id}`, workspaceData);
  return response.data;
};

export const deleteWorkspace = async (id: string) => {
  const response = await api.delete(`/api/workspaces/${id}`);
  return response.data;
};

export const addMemberToWorkspace = async (workspaceId: string, userId: string) => {
  const response = await api.post(`/api/workspaces/${workspaceId}/members`, { memberId: userId });
  return response.data;
};

export const removeMemberFromWorkspace = async (workspaceId: string, userId: string) => {
  const response = await api.delete(`/api/workspaces/${workspaceId}/members/${userId}`);
  return response.data;
};

export const addAdminToWorkspace = async (workspaceId: string, userId: string) => {
  const response = await api.post(`/api/workspaces/${workspaceId}/admins`, { adminId: userId });
  return response.data;
};

export const removeAdminFromWorkspace = async (workspaceId: string, adminId: string) => {
  const response = await api.delete(`/api/workspaces/${workspaceId}/admins/${adminId}`);
  return response.data;
};

export const setWorkspaceInvitation = async (workspaceId: string, role: string) => {
  const response = await api.post(`/api/workspaces/${workspaceId}/invitation`, { role });
  return response.data;
};

export const checkIfInvitationIsValid = async (workspaceId: string, token: string) => {
  const response = await api.get(`/api/workspaces/${workspaceId}/invitation/validate/${token}`);
  return response.data;
};

export const getWorkspaceById = async (id: string) => {
  const response = await api.get(`/api/workspaces/${id}`);
  return response.data;
};

export const addInvitation = async (workspaceId: string, role: string, userId: string) => {
  const response = await api.post(`/api/workspaces/${workspaceId}/invitations`, { role, userId });
  return response.data;
};

export const setInvitations = async (workspaceId: string, invitations: Array<{ email: string, role: string }>) => {
  const response = await api.put(`/api/workspaces/${workspaceId}/invitations`, { invitations });
  return response.data;
};

export const giveUserAppFullAccess = async (userId: string, accessKey: string) => {
  const response = await api.post('/api/auth/give-full-access', { userId, accessKey });
  return response.data;
};

// Check LetsLaunch access by organization key
export const checkLetsLaunchAccess = async (organizationKey: string) => {
  try {
    const response = await api.get(`/api/auth/letslaunch/access/${organizationKey}`);
    return response.data.hasAccess;
  } catch (error: any) {
    throw new Error(`Error checking LetsLaunch access: ${error.response?.data?.message || error.message}`);
  }
};

// Check Builder access by organization key
export const checkBuilderAccess = async (organizationKey: string) => {
  try {
    const response = await api.get(`/api/auth/builder/access/${organizationKey}`);
    return response.data.hasAccess;
  } catch (error: any) {
    throw new Error(`Error checking Builder access: ${error.response?.data?.message || error.message}`);
  }
};

// Get organization details by organization key
export const getOrganizationDetails = async (organizationKey: string) => {
  try {
    const response = await api.post('/organization', { organizationKey });
    return response.data;
  } catch (error: any) {
    throw new Error(`Error fetching organization details: ${error.response?.data?.message || error.message}`);
  }
};

// OpenAI API - Text Generation 
export const getSuggestion = async (prompt: string, maxTokens: number) => {
  try {
    const response = await api.post('/api/openai/suggestion', { prompt, maxTokens });
    return response.data;
  } catch (error) {
    console.error("Error fetching headline suggestion:", error);
    throw error;
  }
};

// Voice Customization API

// Add voice to an actor
export const addVoiceToActor = async (actorId: string, voiceId: string, workspaceId: string, videoPageId: string) => {
  const response = await api.post(`/api/custom-voices/${workspaceId}/${actorId}/voice`, { voiceId, videoPageId });
  return response.data;
};

// Update voice configuration for an actor
export const updateVoiceConfiguration = async (actorId: string, voiceConfig: object) => {
  const response = await api.put(`/api/custom-voices/${actorId}/voice-configuration`, { newSettings: voiceConfig });
  return response.data;
};

// Get voice configuration for an actor
export const getVoiceConfiguration = async (actorId: string) => {
  const response = await api.get(`/api/custom-voices/${actorId}/voice-configuration`);
  return response.data;
};


// Get eleven labs voices
export const getElevenLabsVoices = async (): Promise<ElevenLabsVoice[]> => {
    try {
        const response = await api.get('/api/custom-voices/all');
        return response.data.voices;
    } catch (error) {
        console.error('Error fetching voices:', error);
        throw error;
    }
};

// Get eleven labs voices
export const getElevenLabsVoice = async (voiceId: string): Promise<ElevenLabsVoice> => {
    try {
        const response = await api.get(`/api/custom-voices/${voiceId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching voices:', error);
        throw error;
    }
};

// Remove eleven labs voice data from actor 
export const removeActorCustomVoice = async (actorId: string, workspaceId: string, videoPageId: string) => {
    try {
        const response = await api.delete(`/api/custom-voices/${workspaceId}/${videoPageId}/${actorId}`);
        return response;
    } catch (error) {
        console.error('Error fetching voices:', error);
        throw error;
    }
};

// Heygen Voices API
export const listHeygenVoices = async () => {
  const response = await api.get('/api/heygen-voices');
  return response.data.data.voices;
};

export const addHeygenVoice = async (workspaceId: string, actorId: string, voiceId: string, previewUrl: string, videoPageId: string) => {
  const response = await api.post(`/api/heygen-voices/add-voice/${workspaceId}/${actorId}`, {voiceId, previewUrl, videoPageId});
  return response.data;
};

export const removeHeygenVoice = async (workspaceId: string, actorId: string, videoPageId: string) => {
  const response = await api.delete(`/api/heygen-voices/add-voice/${workspaceId}/${videoPageId}/${actorId}`);
  return response.data;
};

export const elevenLabsFF = async () => {
  const response = await api.get('/api/feature-flip/eleven-labs');
  return response.data;
};

export const testVideoFF = async (email: string) => {
  const response = await api.get(`/api/feature-flip/test-videos/${email}`);
  return response.data;
}
