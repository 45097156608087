import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  voiceCard: {
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderRadius: '8px',
    padding: '16px',
    textAlign: 'center',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    },
  },
  voiceInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '8px',
    '& span': {
      fontWeight: 'bold',
      fontSize: '1rem',
    },
  },
  soundWave: {
    height: '34px',
    width: '150px',
    backgroundImage: 'url("/sound-wave.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  '@keyframes wave': {
    '0%, 100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '50%': {
      transform: 'scale(1.5)',
      opacity: 0.6,
    },
  },
  dialog: {
    position: 'relative',
  },
  dialogTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1), // Adjusts the horizontal spacing from the right
    color: '#FFBF0091', // Set the color of the button icon
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '150px', // Adjust based on your layout needs
  },
  paginationControls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
  dropdown: {
  position: 'absolute',
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  borderRadius: '8px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  maxHeight: '200px',
  overflowY: 'auto',
  zIndex: 1000,
  padding: theme.spacing(1),
  minWidth: '150px',
},
filters: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
