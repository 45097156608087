// src/components/styles.tsx
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    position: 'relative',
  },
  dialogTitle: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.text.primary,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1), // Adjusts the horizontal spacing from the right
    color: '#FFBF0091', // Set the color of the button icon
  },
  filters: {
    display: 'flex',
    gap: theme.spacing(1.5),
    marginBottom: theme.spacing(2),
  },
  actorsGrid: {
    marginTop: theme.spacing(1),
  },
  actorCard: {
    padding: theme.spacing(1),
    borderRadius: 'theme.shape.borderRadius',
    textAlign: 'center',
    cursor: 'pointer',
    border: `2px solid transparent`,
    transition: 'all 0.3s ease',
    '&:hover': {
      borderColor: theme.palette.primary.light,
    },
  },
  selected: {
    borderRadius: '18px',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: '#FFBF0091', // Semi-transparent yellowish color
      zIndex: 2, // Higher than the image/video, but lower than the info text
      borderRadius: 'inherit', // Maintain the same border-radius
    },
  },
  actorImage: {
    width: '100%',
    height: 'auto',
    borderRadius: '18px',
  },
  actorInfo: {
    position: 'absolute', // Position relative to the parent container
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'column',
    bottom: 12,  
    justifyContent: 'center',      
    fontFamily: 'Lexend, sans-serif',
    width: '80%',        // Take full width of the container
    color: 'white',       // Text color to make it readable on the background
    padding: '4px',       // Add padding for better spacing
    textAlign: 'center',  // Center the text
    zIndex: 3,            // Ensure it's on top of the image and video
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    position: 'sticky',  // Keep it fixed within the dialog
    bottom: 0,  // Stick it to the bottom
    left: 0,
    right: 0,  // Span the entire width of the dialog
    zIndex: 10,  // Ensure it is above the content
  },
  nextButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  paginationControls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
  pageIndicator: {
    margin: `0 ${theme.spacing(1)}px`,
    fontWeight: 'bold',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '150px', // Adjust based on your layout needs
  },
  actorVideo: {
    width: '100%',
  },
    selectedActorsSummary: {
    marginTop: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  doneButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '16px',
  },
  actorsList: {
    margin: '16px 0',
  },
  actorItem: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    margin: '8px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  addActorButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
  },
  soundWave: {
    height: '34px',
    width: '150px',
    backgroundImage: 'url("/sound-wave.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
}));

export default useStyles;
