import React from 'react';
import { THEME_COLORS } from '../../context/constants';
import { Typography } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import WarningIcon from '@mui/icons-material/Warning';

const Message: React.FC<{ text: string, type: 'warning' | 'hint' }> = ({ text, type }) => {

  return (
    <Typography variant="body2" style={{marginTop: '6px',fontSize: '12px', color: THEME_COLORS.darkYellow, display: 'flex', alignItems: 'center'}}>
        {type === 'hint'? <LightbulbIcon/> : <WarningIcon />}
        {text}
    </Typography>
  );
};

export default Message;