import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Button,
  CircularProgress,
  InputBase,
  Box,
  Alert,
  Typography,
  Tooltip,
  Chip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Search } from '@mui/icons-material';
import useStyles from './style';
import { spacing, styled } from '@mui/system';
import { HeygenVoice } from '../../../../context/types';
import { THEME_COLORS } from '../../../../context/constants';

const SearchBar = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#f1f1f1',
  border: `1px solid ${theme.palette.divider}`,
  padding: '2px 10px',
  borderRadius: '14px',
  marginBottom: '10px',
  maxWidth: '300px',
}));

interface VoiceSelectionModalProps {
  open: boolean; // Whether the modal is open
  onClose: () => void; // Function to handle closing the modal
  heygenVoices: Array<HeygenVoice>; // List of voices with id and name
  onSelectVoice: (voiceId: string) => void; // Function to handle selecting a voice
}

const VoiceSelectionModal: React.FC<VoiceSelectionModalProps> = ({
  open,
  onClose,
  heygenVoices,
  onSelectVoice,
}) => {
  const classes = useStyles(); // Access styles
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<HTMLAudioElement | null>(null); // Track audio instance
  const [activeLanguage, setActiveLanguage] = useState<string>('All');
  const [activeGender, setActiveGender] = useState<string>('All');
  const [languageDropdownOpen, setLanguageDropdownOpen] = useState(false);

  const voicesPerPage = 20;

  // Extract unique languages and genders
  const availableLanguages = ['All', ...Array.from(new Set(heygenVoices.map((voice) => voice.language)))];


  // Filter voices based on active filters and search query
  const filteredVoices = heygenVoices.filter((voice) => {
    const matchesLanguage = activeLanguage === 'All' || voice.language === activeLanguage;
    const matchesGender = activeGender === 'All' || voice.gender === activeGender;
    const matchesSearchQuery = JSON.stringify(voice).toLowerCase().includes(searchQuery.toLowerCase());
    return matchesLanguage && matchesGender && matchesSearchQuery;
  });

  const totalPages = Math.ceil(filteredVoices.length / voicesPerPage);
  const currentVoices = filteredVoices.slice(
    (currentPage - 1) * voicesPerPage,
    currentPage * voicesPerPage
  );

  const handlePageChange = (direction: string) => {
    if (direction === 'next' && currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    } else if (direction === 'previous' && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const handleFilterChange = (type: 'language' | 'gender', value: string) => {
    if (type === 'language') setActiveLanguage(value);
    if (type === 'gender') setActiveGender(value);
    setCurrentPage(1); // Reset pagination when filter changes
  };

  const handleLanguageChange = (language: string) => {
  setActiveLanguage(language);
  setCurrentPage(1); // Reset to first page after filtering
};

  const handleGenderChange = (gender: string) => {
    setActiveGender(gender);
    setCurrentPage(1); // Reset to first page after filtering
  };

  const handleVoiceClick = async (voice: HeygenVoice) => {
    onSelectVoice(voice.voice_id);
    onClose();
  };

  const playAudio = (audioUrl: string) => {
    stopAudio(); // Stop any currently playing audio
    const audio = new Audio(audioUrl);
    audio.play().catch((error) => console.error("Play failed: ", error));
    setCurrentAudio(audio); // Save reference to the currently playing audio
  };

  const stopAudio = () => {
    if (currentAudio) {
      currentAudio.pause();
      currentAudio.currentTime = 0; // Reset audio to the start
      setCurrentAudio(null); // Clear current audio reference
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <div className={classes.dialog}>
        <DialogTitle>
          Select a Voice
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <div className={classes.loadingContainer}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {/* Search Bar */}
              <Grid container sx={{alignItems: 'center'}}>
                <SearchBar sx={{marginRight: '4px'}}>
                <InputBase
                  placeholder="Search voices..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  fullWidth
                />
                <IconButton>
                  <Search />
                </IconButton>
              </SearchBar>
               <Typography variant="body2" style={{fontSize: '12px', color: THEME_COLORS.darkYellow}}>
                  Hint: you can search for voice name, gender, language, nationality, style...
                </Typography>
              </Grid>
              <Grid>
                <div className={classes.filters}>
                  {/* Gender Filter */}
                  {['All', 'Male', 'Female'].map((gender) => (
                    <Chip
                      key={gender}
                      label={gender}
                      clickable
                      color={activeGender === gender ? 'primary' : 'default'}
                      onClick={() => handleGenderChange(gender)}
                    />
                  ))}

                  {/* Language Filter Dropdown */}
                  <Chip
                    label="Language"
                    clickable
                    onClick={() => setLanguageDropdownOpen((prev) => !prev)}
                    color={activeLanguage !== 'All' ? 'primary' : 'default'}
                  />
                  {languageDropdownOpen && (
                    <Box className={classes.dropdown}>
                      {availableLanguages.map((language) => (
                        <Button
                          key={language}
                          onClick={() => {
                            handleLanguageChange(language);
                            setLanguageDropdownOpen(false);
                          }}
                          style={{
                            textAlign: 'left',
                            width: '100%',
                            color: activeLanguage === language ? THEME_COLORS.darkYellow : 'inherit',
                          }}
                        >
                          {language}
                        </Button>
                      ))}
                    </Box>
                  )}
                </div>
              </Grid>

              {/* Voice List */}
              <Grid container spacing={2} sx={{marginTop: '8px'}}>
                {currentVoices.map((voice) => (
                  <Grid item xs={12} sm={6} md={4} key={voice.voice_id}>
                    <Tooltip title={voice.name}>
                      <div
                        className={classes.voiceCard}
                        onClick={() => handleVoiceClick(voice)}
                        onMouseEnter={() => playAudio(voice.preview_audio)}
                        onMouseLeave={stopAudio}
                      >
                        <div className={classes.voiceInfo}>
                          <span>
                            {voice.name.length > 10 ? `${voice.name.slice(0, 10)}...` : voice.name}
                          </span>
                          <div className={classes.soundWave} />
                        </div>
                      </div>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>

              {/* Pagination Controls */}
              <div className={classes.paginationControls}>
                <Button
                  variant="contained"
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange('previous')}
                >
                  Previous
                </Button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <Button
                  variant="contained"
                  disabled={currentPage === totalPages}
                  onClick={() => handlePageChange('next')}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default VoiceSelectionModal;
