import { getUserById, getWorkspaceById } from "../client/api";
import { User, Workspace } from "../context/types";

//ENUMS
export enum USER_ROLE {
  admin = 'admin',
  user = 'user',
  limited = 'limited',
}

export enum WORKSPACE_CATEGORY {
  fullAccess = 'fullaccess',
  limited = 'limited',
}

export enum USER_WORKSPACE_ROLE {
  admin = 'admin',
  member = 'member',
}

//FUNCTIONS
export const encodeInvitationKey = (key: string) => {
  return btoa(key); // Use btoa for Base64 encoding in the browser
};

export const fetchUser = async (id?: string) => {
      const userId = id ?? localStorage.getItem('userId'); // Get userId from local storage
      if (userId) {
        try {
          const userData = await getUserById(userId); // Fetch user from API
          return userData
        } catch (error) {
          console.error('Failed to fetch user:', error);
        }
      }
      return null
};

export const fetchWorkspace = async (workspaceId?: string) => {
        if(!workspaceId) return
        const workspace = await getWorkspaceById(workspaceId);
        return workspace;
}

export const canGenerateVideos = (workspace: Workspace | null, user: User | null): boolean => {
  if(!workspace || !user) return false
  const isAppAdmin = user.role === 'admin';
  const isWorkspaceAdmin = workspace.admins?.some(admin => admin._id === user._id);
  const isWorkspaceOwner = workspace.owner._id === user._id;
  const isFullAccessWorkspace = workspace.category === 'fullaccess';
  const hasExceededVideoLimit = user.videoGenerations.some(
    video => video.workspaceId === workspace._id && video.numberOfGeneratedVideos > 5
  );
  const isAdminAndFullAccess = (isWorkspaceOwner || isWorkspaceAdmin) && isFullAccessWorkspace;

  // Return true if the user can generate videos
  return (
    isAppAdmin || 
    isAdminAndFullAccess || 
    (isFullAccessWorkspace && !hasExceededVideoLimit) 
  );
};

export const generateVariableScripts = (currentScript: any, baseScript: string): string[] => {
    const { variables } = currentScript;

    // Helper function to replace variables in the script
    const replaceVariables = (template: string, values: Record<string, string>) => {
        return template.replace(/\{\{(.*?)\}\}/g, (_, key) => values[key.trim()] || "");
    };

    // Generate all combinations of variables
    const combinations = generateVariableCombinations(variables);

    // Replace variables in the script
    return combinations.map((combo) => replaceVariables(baseScript, combo));
};

// Generate combinations of variable values
export const generateVariableCombinations = (variables: any[]): Record<string, string>[] => {
    if (!variables || variables.length === 0) return [{}];

    const [currentVar, ...rest] = variables;
    const restCombos = generateVariableCombinations(rest);

    return currentVar.values.flatMap((value: string) =>
        restCombos.map((combo) => ({
            ...combo,
            [currentVar.name]: value,
        }))
    );
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));


