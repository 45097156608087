import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { THEME_COLORS } from '../../context/constants';

const useStyles = makeStyles((theme: Theme) => ({
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10px',
  },
  playButton: {
    background: 'none',
    border: 'none',
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginLeft: '4px',
    '& svg': {
      color: '#FFC107',
    },
  },
  soundWave: {
    height: '34px',
    width: '150px',
    backgroundImage: 'url("/sound-wave.png")',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  audioTimer: {
    fontSize: '14px',
    color: '#555',
    marginLeft: '6px',
  },
  checkbox: {
    marginRight: '10px',
    '& svg':{
      fill: THEME_COLORS.yellow,
    }
  },

  // Style for the avatar image
  avatarThumbnail: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    objectFit: 'cover',
  },

  radio: {
    color: THEME_COLORS.darkYellow, // Yellow color for the radio button
    '&.Mui-checked': {
      color: THEME_COLORS.darkYellow, // Yellow when checked
    },
    '& span': {
      color: THEME_COLORS.darkYellow, // Yellow when checked
    },
  },
  label: {
    fontSize: '12px', // Set font size for the label
  },
  voiceSettingsPanel: {
    marginTop: '10px',
    padding: '8px',
    border: '1px solid #eee',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    
    '& span': {
      fontSize: '12px',
    },
    '& .MuiSlider-root': {
      width: '70%',
    },
    '& .MuiSwitch-root': {
      marginLeft: '10px',
    },
  },
  settings: {
    
  },
  settingsRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  voiceButton: {
    fontSize: '12px',
    width: '100%',
    maxHeight: '30px',
    marginLeft: '8px',
    justifyContent: 'space-between',
    border: '1px solid #ccc',
    borderRadius: '4px',
    textTransform: 'none',
    padding: '5px 12px',
    color: '#000',
    whiteSpace: 'nowrap', // Prevent text wrapping
    overflow: 'hidden', // Hide overflowed text
    textOverflow: 'ellipsis', // Add ellipsis for overflowed text
    cursor: 'pointer',
    fontFamily: 'Lexend, sans-serif',
    '&:hover': {
      backgroundColor: '#f9f9f9',
    },
  }
}));

export default useStyles;