import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  videoPage: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    fontFamily: 'Lexend, sans-serif !important',
  },
  videoPageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  columnsContainer: {

  },
  titleMenu: {
    display: 'flex',
    alignContent: 'center'
  },
  videoPageActions: {
    display: 'flex',
    marginLeft: '18px'
  },
  videoPageContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  scriptSection: {
    flex: 0.6,
    paddingRight: '20px',
  },
  scriptTitle: {
    display: 'flex',
    gap: 5,
    alignItems: 'center',
    marginBottom: '10px', /* Adjust the spacing if needed */
    paddingBottom: '10px',
    height: '60px'
  },
  divider: {
    borderBottom: '4px solid black',
  },
  generatedVideosSection: {
    flex: 0.4,
  },
  scriptPart: {
    marginBottom: '20px',
    marginTop: '16px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#000',
  },
  label: {
    display: 'block',
    fontSize: '14px',
    alignSelf: 'center',
    alignItems: 'center',
  },
  scriptSectionTitle: {
    backgroundColor: '#D9D9D9',
    borderRadius: '46px',
    padding: '8px 14px',
    width: '50%',
    textTransform: 'uppercase',
    marginBottom: '10px',
    maxWidth: '150px'
  },
  scriptToggle: {
    display: 'flex',
    alignItems: 'center',
  },
  scriptMenu: {

  },
  scriptActions: {
    display: 'flex',
    alignItems: 'center',
  },
  textarea: {
    width: '100%',
    maxWidth: '400px',
    height: '80px',
    marginRight: '10px',
    padding: '10px',
    border: '1px solid #d9d9d9',
    borderRadius: '12px',
  },
  scriptActionItems: {

  },
  button: {
    marginLeft: '5px',
    padding: '8px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  videoPageFooter: {
    marginTop: '20px',
    textAlign: 'right',
  },
  saveButton: {
    padding: '10px 20px',
    backgroundColor: '#ffcc00',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e6b800',
    },
  },
  addActorsText: {
    color:'#000',
    fontSize: '16px',
    fontWeight: 'bold',
    padding: '10px',
    opacity: '0.6'
  },
  selectedActorsList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: '16px',
  },
  actorItem: {
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    margin: '8px',
    textAlign: 'center',
  },
  addActorButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
  },
    // Style for the avatar list section
  avatarListSection: {
    marginTop: '16px',
  },
  
  // Title of the avatar section
  avatarListTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: '#333',
  },

  // Container for the avatar list
  avatarList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    marginTop: '16px',
  },

  // Style for each individual avatar item
  avatarItem: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    borderRadius: '8px',
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.15)',
    textAlign: 'center',
    backgroundColor: '#f8f8f8',
  },

  // Style for the avatar image
  avatarThumbnail: {
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginBottom: theme.spacing(1),
  },

  // Style for the actor's name
  avatarName: {
    fontSize: '0.9rem',
    fontWeight: '500',
    color: '#555',
  },

  // Loading spinner style
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100px',
  },

  generateVideosButton: {
    backgroundColor: '#FFBF00 !important',  /* Yellow, matching the style */
    border: 'none',
    color: '#000 !important',               /* Black text for contrast */
    padding: '10px 20px !important',        /* Padding for size */
    fontSize: '14px !important',            /* Prominent font size */
    fontWeight: 'bold !important',
    borderRadius: '5px',         /* Rounded corners */
    cursor: 'pointer',           /* Pointer on hover */
    marginTop: '20px !important',           /* Spacing from other elements */
    display: 'flex',
    alignItems: 'center',        /* Align the icon and text */
    gap: '10px',                 /* Space between icon and text */
    transition: 'background-color 0.3s ease', /* Smooth hover transition */
    fontFamily: 'Lexend, sans-serif',
    '&:hover': {
      backgroundColor: '#e6ac00 !important', /* Darker yellow on hover */
    },
  },
  generatedVideosNavigationButton: {
    border: 'none',
    width: '100%',
    color: '#FFBF00 !important',               /* Black text for contrast */
    background: 'none',
    fontSize: '14px !important',            /* Prominent font size */
    fontWeight: 'bold !important',
    cursor: 'pointer',           /* Pointer on hover */
    marginTop: '20px !important',           /* Spacing from other elements */
    marginBottom: '20px !important',
    marginLeft: 0,
    display: 'flex',
    alignItems: 'center',        /* Align the icon and text */
    justifyContent: 'left',
    gap: '10px',                 /* Space between icon and text */
    padding: 0,
    fontFamily: 'Lexend, sans-serif',
    '&:hover': {
      color: '#e6ac00 !important', /* Darker yellow on hover */
    },
  },
  lastUpdatedBy: {
    fontSize: '12px',
    color: '#636363',
  },
  reloadButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    opacity: 0.6,
    padding: '8px',
    '&:hover': {
      transform: 'rotate(90deg)',
      transition: 'transform 0.3s ease',
    },
  },
  videoActionButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    padding: '8px',
    opacity: 0.6,
  },
  videoWrapper: {

  },
  generatedVideosList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop: '16px',
  },
  generatedVideoItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '10px',
    backgroundColor: '#f7f7f7',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  videoPlayer: {
    width: '120px',
    height: '80px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    width: '400px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export default useStyles;
