import React, { createContext, useState, useContext, ReactNode } from 'react';
import { User, Workspace } from './types'; // Assuming Workspace type is defined in 'types'
import Cookies from 'js-cookie';

interface UserContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  currentWorkspace: Workspace | null;
  setCurrentWorkspace: React.Dispatch<React.SetStateAction<Workspace | null>>;
  logout: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider = ({ children }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | null>(null);

  const logout = () => {
    setUser(null);
    setCurrentWorkspace(null); // Reset workspace on logout

    // Remove the authInfo cookie
    Cookies.remove('authInfo', {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: '/',
    });
  };

  return (
    <UserContext.Provider value={{ user, setUser, currentWorkspace, setCurrentWorkspace, logout }}>
      {children}
    </UserContext.Provider>
  );
};
